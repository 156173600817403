import React, { useMemo, useState } from "react";
import {
  Form,
  Radio,
  RadioGroup,
  SelectPicker,
  DateRangePicker,
  useToaster,
  Notification,
  IconButton,
  DatePicker,
  Stack,
} from "rsuite";
import Header from "../component/Header";
import SearchIcon from "@rsuite/icons/Search";
import axios from "axios";
import Table from "react-bootstrap/Table";
import moment from "moment";

import { firstDay } from "../Constants";
import { Table2XLSX } from "../functions";

const ViewAttendance = () => {
  const [radio, setRadio] = useState("A");

  const [selectedDate, setselectedDate] = useState(new Date());
  const [selectedNameID, setselectedNameID] = useState("");
  const [LabourList, setLabourList] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [dataList2, setdataList2] = useState([]);
  const [processing, setProcessing] = useState(false);

  const [processing2, setProcessing2] = useState(false);
  const toaster = useToaster();
  const [selectedLabour, setSelectedLabour] = useState("");
  const [selectedDateRange, setselectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);

  const [attendance, setAttendance] = useState("");
  const [amount, setAmount] = useState("");

  const column = useMemo(
    () => [
      { field: "Date", title: "Date" },
      { field: "Attendance", title: "Attendance" },
      { field: "Rate", title: "Rate" },
      { field: "Salary", title: "Salary" },
      { field: "Tea", title: "Tea" },
      { field: "Total", title: "Total" },
      { field: "SiteName", title: "SiteName" },
    ],
    []
  );

  const column2 = useMemo(
    () => [
      { field: "Name", title: "Name" },
      { field: "Date", title: "Date" },
      { field: "Attendance", title: "Attendance" },
      { field: "Rate", title: "Rate" },
      { field: "Salary", title: "Salary" },
      { field: "Tea", title: "Tea" },
      { field: "Total", title: "Total" },
      { field: "SiteName", title: "SiteName" },
    ],
    []
  );

  const getLabourList = () => {
    setLabourList([]);
    axios
      .get("https://api.laxmaninterior.in/GetLabours.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const data = data1.Data.map((item) => ({
            id: item.SrNo,
            label: item.Name,
            value: item.Name,
          }));
          /*           data1.Data.forEach((r) => {
            setLabourList((a) => {
              return [...a, { id: r.SrNo, value: r.Name, label: r.Name }];
            });
          }); */
          setLabourList(data);
        }
        if (data1.status === 0) {
          setLabourList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRadio = (e) => {
    setRadio(e);
  };

  const handleSearchforLabour = () => {
    if (selectedNameID === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please enter Labour name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }
    const datatoSend = {
      LabourID: selectedNameID,
      Month: moment(selectedDate).format("YYYY-MM-DD"),
    };

    axios
      .post(
        "https://api.laxmaninterior.in/GetMonthlyDataForLabour.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;

        if (data1.status === 1) {
          setdataList(data1.Data);
          setAttendance(data1.Attendance);
          setAmount(data1.Total);
        }
        if (data1.status === 0) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              No records
            </Notification>,
            { placement: "topEnd" }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toaster.push(
          <Notification type="error" header="Error" duration={1000}>
            {error}
          </Notification>,
          { placement: "topEnd" }
        );
      });
  };

  const handleSearchforLabourByDate = () => {
    setProcessing2(true);
    if (selectedDateRange.length < 2) {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Please select date ranges
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing2(false);
      return;
    }

    const datatoSend = {
      StartDate: moment(selectedDateRange[0]).format("YYYY-MM-DD"),
      EndDate: moment(selectedDateRange[1]).format("YYYY-MM-DD"),
    };
    axios
      .post(
        "https://api.laxmaninterior.in/GetAllLabourBetweenDate.php",
        datatoSend
      )
      .then(function (response) {
        const data1 = response.data;
        console.log(data1);

        if (data1.status === 1) {
          data1.Data.forEach((r) => {
            setdataList2(data1.Data);
          });
          setAttendance(data1.Attendance);
          setAmount(data1.Total);
        }
        if (data1.status === 0) {
        }
        setProcessing2(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing2(false);
      });
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) getLabourList();
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Track Attendance</h4>
      </Header>
      <div className="InputContainer">
        <Form.Group controlId="radioList">
          <RadioGroup
            name="radioList"
            inline
            appearance="picker"
            defaultValue="A"
            onChange={(e) => {
              handleRadio(e);
            }}
            value={radio}
          >
            <Radio value="A">Report by Labour</Radio>
            <Radio value="D">Date wise Search</Radio>
          </RadioGroup>

          <hr />
        </Form.Group>

        <Stack spacing={6} style={{ marginBottom: 10 }}>
          {radio === "A" && (
            <Stack spacing={15}>
              <label>Date:</label>
              <DatePicker
                oneTap
                format="MMM-yyyy"
                cleanable={false}
                defaultValue={firstDay}
                ranges={[]}
                onSelect={(e) => setselectedDate(e)}
                style={{ width: 130 }}
              />
              <label>Labour Name:</label>
              <SelectPicker
                cleanable={false}
                block={true}
                data={LabourList}
                appearance="default"
                placeholder="Select Labour"
                value={selectedLabour}
                onSelect={(e, Item) => {
                  setSelectedLabour(e);
                  setselectedNameID(Item.id);
                }}
              />

              <IconButton
                onClick={handleSearchforLabour}
                loading={processing}
                icon={<SearchIcon />}
                color="info"
                appearance="primary"
              >
                Search
              </IconButton>

              <Table2XLSX />
            </Stack>
          )}

          {radio === "D" && (
            <Stack spacing={4}>
              <label>Date Between:</label>
              <DateRangePicker
                cleanable={false}
                value={selectedDateRange}
                onChange={setselectedDateRange}
              />

              <IconButton
                onClick={handleSearchforLabourByDate}
                loading={processing2}
                icon={<SearchIcon />}
                color="info"
                appearance="primary"
              >
                Search
              </IconButton>

              <Table2XLSX />
            </Stack>
          )}
          <Stack spacing={4}>
            <span style={{ fontSize: 15 }}>Attendance</span>{" "}
            {` : ${Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 2,
            }).format(attendance)}`}
            &nbsp; &nbsp;
            <span style={{ fontSize: 15 }}>Salary</span>{" "}
            {` : ${Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 2,
            }).format(amount)}`}
          </Stack>
        </Stack>
      </div>
      <div
        className="InputContainer"
        style={{ maxHeight: 550, overflow: "auto" }}
      >
        {radio === "A" && (
          <Table
            striped
            bordered
            hover
            id="TableData"
            style={{ position: "relative", maxHeight: 550 }}
          >
            <thead>
              <tr>
                {column.map((a) => (
                  <th
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey",
                    }}
                    key={a.field}
                  >
                    {a.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.Date}>
                  <td>{a.Date}</td>
                  <td>{a.Attendance === "0.00" ? "" : a.Attendance}</td>
                  <td>
                    {a.Attendance === "0.00"
                      ? ""
                      : Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 2,
                        }).format(a.PerDayRate)}
                  </td>
                  <td>
                    {a.Attendance === "0.00"
                      ? ""
                      : Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 2,
                        }).format(a.Salary)}
                  </td>
                  <td>
                    {a.Attendance === "0.00"
                      ? ""
                      : Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 2,
                        }).format(a.Tea)}
                  </td>
                  <td>
                    {a.Attendance === "0.00"
                      ? ""
                      : Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 2,
                        }).format(a.Total)}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>
                    {a.Attendance === "0.00" ? "" : a.SiteName}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {radio === "D" && (
          <Table
            id="TableData"
            style={{ position: "relative", maxHeight: 550 }}
          >
            <thead>
              <tr>
                {column2.map((a) => (
                  <th
                    key={a.field}
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey",
                    }}
                  >
                    {a.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList2.map((a) => (
                <tr key={a.SrNo}>
                  <td style={{ textTransform: "capitalize" }}>{a.Name}</td>
                  <td>{a.Date}</td>
                  <td>{a.Attendance}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.PerDayRate)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Salary)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Tea)}
                  </td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Total)}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>{a.SiteName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default ViewAttendance;
