import React, { useState } from "react";
import { Button } from "rsuite";

export default function CalmButton(props) {
  const [executing, setExecuting] = useState(false);

  const { disabled, onClick, title, ...otherProps } = props;

  const onRealClick = async (event) => {
    setExecuting(true);
    try {
      await onClick();
    } finally {
      setExecuting(false);
    }
  };

  return (
    <Button
      onClick={onRealClick}
      disabled={executing || disabled}
      appearance="primary"
      {...otherProps}
    >
      {title}
    </Button>
  );
}
