import React from 'react'
import {
    Button,
  } from "rsuite";
// import { read, writeFileXLSX } from "xlsx";
// import * as XLSX from 'https://cdn.sheetjs.com/xlsx-0.18.9/package/xlsx.mjs';
const Test = () => {
    const [data, setData] = React.useState([[ "id",    "name", "value" ],[    1, "sheetjs",    7262 ],[    2, "js-xlsx",    6969 ]]);
    function handleClick() {
        // var worksheet = XLSX.utils.aoa_to_sheet(data);
        // var new_workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
        // XLSX.writeFile(new_workbook, "out.xlsb");

    }
  return (
    <Button onClick={handleClick}>Search</Button>
  )
}

export default Test