
export const AdminID = "miY0rkS9NIY7NRcxhrmlV7fNKT72"
export const data = [
    {
      label: "Eugenia",
      value: "Eugenia",
      role: "Master",
    },
    {
      label: "Kariane",
      value: "Kariane",
      role: "Master",
    },
    {
      label: "Louisa",
      value: "Louisa",
      role: "Master",
    },
    {
      label: "Marty",
      value: "Marty",
      role: "Master",
    },
    {
      label: "Kenya",
      value: "Kenya",
      role: "Master",
    },
    {
      label: "Hal",
      value: "Hal",
      role: "Developer",
    },
    {
      label: "Julius",
      value: "Julius",
      role: "Developer",
    },
    {
      label: "Travon",
      value: "Travon",
      role: "Developer",
    },
    {
      label: "Vincenza",
      value: "Vincenza",
      role: "Developer",
    },
    {
      label: "Dominic",
      value: "Dominic",
      role: "Developer",
    },
    {
      label: "Pearlie",
      value: "Pearlie",
      role: "Guest",
    },
    {
      label: "Tyrel",
      value: "Tyrel",
      role: "Guest",
    },
    {
      label: "Jaylen",
      value: "Jaylen",
      role: "Guest",
    },
    {
      label: "Rogelio",
      value: "Rogelio",
      role: "Guest",
    },
  ];

  export const Frequency = [
    { key: "One Time", label: "OneTime", value:"One Time" },
    { key: "Daily", label: "Daily", value:"Daily" },
    { key: "Weekly", label: "Weekly", value:"Weekly" },
    { key: "Monthly", label: "Monthly", value:"Monthly" },
    { key: "Bi-Monthly", label: "Bi-Monthly", value:"Bi-Monthly" },
    { key: "Quaterly", label: "Quaterly", value:"Quaterly" },
    { key: "Half Yearly", label: "Half Yearly", value:"Haly Yearly" },
    { key: "Yearly", label: "Yearly", value:"Yearly" }
  ];

  let i = 0;
  export const Categories = [
    { key: ++i, label: "Salary",value: "Salary", },
    { key: ++i, label: "Advance", value: "Advance" },
    { key: ++i, label: "Material", value:"Material" },
    { key: ++i, label: "Cash" , value:"Cash"},
    { key: ++i, label: "Purchased", value:"Purchased" },
    { key: ++i, label: "Other" , value:"Other"},
  ];

  i = 0;
  export const LabourCategories = [
    { key: ++i, label: "Salary",value: "Salary", },
    { key: ++i, label: "Advance", value: "Advance" },
  ];

  i = 0;
  export const Month = [
    { key: ++i, label: "Last Month", value: "Last Month" },
    { key: ++i, label: "Current Month",value: "Current Month"},
    { key: ++i, label: "Custom", value:"Custom" },
  ];
  i = 0;
  export const Year = [
    { key: ++i, label: "Last Calender Year",value: "Last Calender Year", },
    { key: ++i, label: "Last Financial Year", value: "Last Financial Year" },
    { key: ++i, label: "Current Calender Year", value:"Current Calender Year" },
    { key: ++i, label: "Current Financial Year" , value:"Current Financial Year"},
    { key: ++i, label: "Custom", value:"Custom" },
  ];