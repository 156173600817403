import React from "react";
import Sidebar from "../component/Sidebar";
import "./Main.css";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../img/Logo.png";


function Main() {
  const displayName = useSelector((state) => state.nameReducer.user.userDetails);
  //const Type = displayName.Type;
  console.log(displayName);
  const [activeKey, setActiveKey] = React.useState("1");
  const [openKeys, setOpenKeys] = React.useState(["3"]);
  const [expanded, setExpand] = React.useState(false);

  React.useEffect(() => {
    //console.log(displayName);
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <div className="main-container">
      <section className="sidebar">
        <div>
          <div
            style={{
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
              marginBottom: "15px",
              marginLeft: "-10px",
            }}
          >
            <img height={80} width={220} src={Logo} alt="Logo" />
          </div>
          <Sidebar
            activeKey={activeKey}
            openKeys={openKeys}
            onOpenChange={setOpenKeys}
            onSelect={setActiveKey}
            onExpand={setExpand}
            expanded={expanded}
            appearance="inverse"
          />
        </div>
      </section>
      <main className="main">
        <header className="header">
          <nav className="navbar navbar-expand-lg transparent">
            <a className="navbar-brand" href="/Main/Dashboard">
              Home
            </a>

            <div className="collapse navbar-collapse">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    Welcome <span className="sr-only">{ displayName.Name}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="" style={{ marginLeft: "0" }}>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <section className="body">
          <section className="content">
            <Outlet />
          </section>
        </section>
      </main>
    </div>
  );
}

export default Main;
