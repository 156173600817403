import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  useToaster,
  Notification,
  IconButton,
  AutoComplete,
  DateRangePicker,
  Stack,
} from "rsuite";
import Header from "../component/Header";

import TrashIcon from "@rsuite/icons/Trash";
import { confirmAlert } from "react-confirm-alert"; // Import

import axios from "axios";
import Table from "react-bootstrap/Table";
import moment from "moment";

import { useSelector } from "react-redux";
import sendEmail from "../component/sendEmail";
import { Label } from "@mui/icons-material";

const OtherLaboursPDF = () => {
  const displayName = useSelector(
    (state) => state.nameReducer.user.userDetails
  );
  const Type = displayName.Type;

  const [dataList, setdataList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [processing, setProcessing] = useState(false);
  const toaster = useToaster();
  const [searchText, setSearchText] = useState("");
  const [site, setSite] = useState("");
  /* const [films1, setFilms1] = useState([]); */
  const [siteList, setSiteList] = useState([]);
  const [amnt, setAmnt] = useState(0);
  const [travel, setTravel] = useState();

  const [vendorList, setVendorList] = useState([]);
  const [value, setValue] = React.useState([]);
  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      //getLabourList();
      getSitesList();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const column = [
    { field: "Date", title: "Date" },
    { field: "Name", title: "Name" },
    { field: "PerDayRate", title: "PerDayRate" },
    { field: "Attendance", title: "Attendance" },
    { field: "Total", title: "Total" },
    { field: "Sitename", title: "Sitename" },
  ];

  const columnUser = [
    { field: "Date", title: "Date" },
    { field: "Name", title: "Name" },
    { field: "Attendance", title: "Attendance" },
    { field: "PerDayRate", title: "PerDayRate" },
    { field: "Total", title: "Total" },
    { field: "Sitename", title: "Sitename" },
  ];
  const getVendors = () => {
    axios
      .get("https://api.laxmaninterior.in/GetVendorName.php")
      .then((a) => {
        const result = a.data;
        if (result.status === 1) {
          const test = result.Data.map(({ Name }) => Name);
          setVendorList(test);
          // console.log(result.Data)
        }
        if (result.status === 0 || result.status === 2) {
          setVendorList([]);
        }
      })
      .catch((a) => {
        console.log(a);
      });
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getVendors();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  /*   const getSitesList = () => {
    axios
      .get("https://api.laxmaninterior.in/GetSitesForDropdown.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSiteList(data1.Data);
        }
        if (data1.status === 0) {
          setSiteList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }; */
  const getSitesList = () => {
    axios
      .get("https://api.laxmaninterior.in/GetSitesForOtherLabour.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          const test = data1.Data.map(({ label }) => label);
          // console.log(test);
          setSiteList(test);
        }
        if (data1.status === 0) {
          setSiteList([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    var isSubscribed = true;
    if (isSubscribed) {
      if (searchText === "") {
        setFilteredList(dataList);
        setFilteredTotal(amnt);
      } else {
        let filterData = dataList.filter((item) =>
          item.Sitename.toLowerCase().includes(searchText)
        );
        let total = 0;
        filterData.map((item) => {
          total = parseInt(item.Amount) + total;
        });
        setFilteredList(filterData);
        setFilteredTotal(total);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [searchText]);
  /* toaster.push(
    <Notification type="error" header="Error" duration={1000}>
      Please select date
    </Notification>,
    { placement: "topEnd" }
  ); */

  function Generate() {
    axios
      .post(
        "https://api.laxmaninterior.in/GetOtherLabourInvoicePDF.php",
        {
          Site: site,
          Start: moment(value[0]).format("YYYY-MM-DD"),
          End: moment(value[1]).format("YYYY-MM-DD"),
          Name: searchText,
          Travel: travel,
        },
        {
          responseType: "blob",
          headers: { "Content-type": "application/json" },
        }
      )
      .then((response) => {
        // Handle the PDF blob
        var binaryData = [];
        binaryData.push(response.data);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        //  const url = window.URL.createObjectURL(response);

        // Create a link and trigger a download
        const a = document.createElement("a");
        a.href = url;
        a.download = `${moment(value[0]).format("YYYY-MM-DD")}-${moment(
          value[1]
        ).format("YYYY-MM-DD")}.pdf`;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleDelete = (id, data = { Name: "", Date: "", Attendance: "" }) => {
    if (id === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${data.Name}'s Attendance on ${data.Date}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: id };
            console.log(datatoSend);
            axios
              .post(
                "https://api.laxmaninterior.in/DeleteFromOtherWorker.php",
                datatoSend
              )
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  //  handleSearchforLabour();
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${data.Name}'s Attendance ${data.Attendance} on ${data.Date}.`,
                    `Deleted from Other Workers(${data.Name}) `,
                    "Successfully deleted"
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    `Deleted ${data.Name}'s Attendance on ${data.Date}.`,
                    `Deleted from Other Workers(${data.Name}) `,
                    "Successfully deleted"
                  );
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Other Workers`,
                    "Error in Deleting from Other Workers"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Other Worker(${data.Name})`,
                  "Error in Deleting from Other Worker"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Generate PDF</h4>
      </Header>
      <div className="InputContainer">
        <Form layout="inline">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <Stack spacing={6}>
                <label>Date:</label>
                <DateRangePicker
                  value={value}
                  onChange={setValue}
                  placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
                  format="dd-MM-yyyy"
                  cleanable={false}
                  width={350}
                />
                <label>Site:</label>
                <AutoComplete
                  data={siteList}
                  value={site}
                  onChange={(e) => setSite(e)}
                  style={{ width: 250 }}
                />
                <Button
                  color="green"
                  appearance="primary"
                  onClick={Generate}
                  loading={processing}
                >
                  Generate
                </Button>
              </Stack>
            </div>
            <div style={{ justifyContent: "center" }}>
              <div>&nbsp;</div>
            </div>
            <div style={{ justifyContent: "center", alignItems: "center" }}>
              <div>
                {Type === "Admin" && (
                  <div>
                    {" "}
                    <b>Total</b> :{" "}
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(filteredTotal)}
                  </div>
                )}
                &nbsp;
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="InputContainer" style={{ marginBottom: 10 }}>
        <Stack spacing={6}>
          <label>Name : </label>
          <Input
            placeholder="Enter client name"
            value={searchText}
            onChange={(e) => {
              setSearchText(e);
              console.log(e);
            }}
            style={{ width: 250 }}
          />
          <label>Travel / Material Cost : </label>
          <Input
            placeholder="Travel / Material"
            value={travel}
            onChange={(e) => {
              setTravel(e);
              console.log(e);
            }}
          />
          &nbsp; &nbsp;
        </Stack>
      </div>
      <div className="InputContainer">
        {Type === "Admin" ? (
          <Table striped bordered hover id="TableData">
            <thead>
              <tr>
                {column.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.Date}</td>
                  <td style={{ textTransform: "capitalize" }}>{a.Name}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.PerDayRate)}
                  </td>
                  <td>{a.Attendance}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>{a.Sitename}</td>
                  <td width={60}>
                    <IconButton
                      icon={<TrashIcon />}
                      color="red"
                      appearance="primary"
                      circle
                      onClick={() =>
                        handleDelete(a.SrNo, {
                          Name: a.Name,
                          Date: a.Date,
                          Attendance: a.Attendance,
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Table striped bordered hover id="TableData">
            <thead>
              <tr>
                {columnUser.map((a) => (
                  <th key={a.field}>{a.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList.map((a) => (
                <tr key={a.SrNo}>
                  <td>{a.Date}</td>
                  <td style={{ textTransform: "capitalize" }}>{a.Name}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.PerDayRate)}
                  </td>
                  <td>{a.Attendance}</td>
                  <td>
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 2,
                    }).format(a.Amount)}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>{a.Sitename}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default OtherLaboursPDF;
