import React, { useState } from "react";
import {
  Button,
  Input,
  Form,
  Modal,
  useToaster,
  Notification,
  Tooltip,
  Whisper,
  IconButton,
  Stack,
} from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
/*import EditIcon from "@rsuite/icons/Edit"; */
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import Header from "../component/Header";
import Table from "react-bootstrap/Table";
import ReloadIcon from "@rsuite/icons/Reload";
import sendEmail from "../component/sendEmail";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; //

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
const ManageSites = () => {
  const [data, setData] = useState([]);
  /*   const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false); */
  const [processing, setProcessing] = useState(false);
  const [processing1, setProcessing1] = useState(false);

  const [siteList, setSiteList] = useState([]);
  const column = React.useMemo(
    () => [
      { field: "SiteName", title: "Site Name" },
      { field: "Desc", title: "Notes" },
      { field: "Delete", title: "Delete" },
    ],
    []
  );
  const [value, setValue] = React.useState({
    SiteName: "",
    Desc: "",
  });
  const toaster = useToaster();

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) handleRefresh();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleRefresh = () => {
    setProcessing1(true);
    setSiteList([]);
    axios
      .get("https://api.laxmaninterior.in/GetSites.php")
      .then(function (response) {
        const data1 = response.data;
        if (data1.status === 1) {
          setSiteList(data1.Data);
          var outputData = siteList.map(Object.values);
          setData(outputData);
        }
        if (data1.status === 0) {
        }
        setProcessing1(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing1(false);
      });
  };

  const SaveData = async () => {
    setProcessing(true);

    if (value.SiteName === "") {
      toaster.push(
        <Notification type="error" header="error" duration={1000}>
          Enter Site Name
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    const data = {
      Name: value.SiteName,
      Desc: value.Desc,
    };
    axios
      .post("https://api.laxmaninterior.in/AddSite.php", data, {
        headers: { "Content-type": "application/json" },
      })
      .then((response) => {
        const result = response.data;
        console.log(result);
        if (result.status === 1) {
          toaster.push(
            <Notification type="success" header="success" duration={1000}>
              {result.Success}
            </Notification>,
            { placement: "topEnd" }
          );
          handleRefresh();
          clearInput();
        }
        if (result.status === 2) {
          toaster.push(
            <Notification type="error" header="Error" duration={1000}>
              {`${value.SiteName} Already exists`}
            </Notification>,
            { placement: "topEnd" }
          );
        }
        setProcessing(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  function clearInput() {
    setValue({
      SiteName: "",
      Desc: "",
    });
  }

  function handleDelete(id, Name) {
    if (id === "") {
      toaster.push(
        <Notification type="error" header="Error" duration={1000}>
          Something went wrong.
        </Notification>,
        { placement: "topEnd" }
      );
      setProcessing(false);
      return;
    }

    confirmAlert({
      title: "Confirm?",
      message: `Are you sure to delete ${Name} from Site list ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const datatoSend = { ID: id };
            console.log(datatoSend);
            axios
              .post("https://api.laxmaninterior.in/DeleteSite.php", datatoSend)
              .then(function (response) {
                const data1 = response.data;
                if (data1.status === 1) {
                  toaster.push(
                    <Notification
                      type="success"
                      header="Deleted"
                      duration={1000}
                    >
                      Deleted successfully
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "magharamsuthar41@gmail.com",
                    `Deleted ${Name} from Sites`,
                    `Deleted from Sites(${Name})`,
                    "Successfully deleted"
                  );
                  handleRefresh();
                }
                if (data1.status === 0) {
                  toaster.push(
                    <Notification type="error" header="Error" duration={1000}>
                      Something went wrong. Please refresh and try again later.
                    </Notification>,
                    { placement: "topEnd" }
                  );
                  sendEmail(
                    "akv7@ymail.com",
                    "Something went wrong. Please refresh and try again later.",
                    `Delete from Site(${Name})`,
                    "Error in Deleting from Site"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                toaster.push(
                  <Notification type="error" header="Error" duration={1000}>
                    {error}
                  </Notification>,
                  { placement: "topEnd" }
                );
                sendEmail(
                  "akv7@ymail.com",
                  error,
                  `Delete from Site (${Name})`,
                  "Error in Deleting from Site"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  return (
    <div>
      <Header>
        <h4 style={{ color: "white", fontSize: 25 }}>Add / List Sites</h4>
      </Header>
      <div className="Subbody">
        <Stack spacing={10}>
          <label>Site : </label>
          <Whisper trigger="focus" speaker={<Tooltip>Required</Tooltip>}>
            <Input
              style={{ width: 300 }}
              placeholder="Enter Site name"
              value={value.SiteName}
              onChange={(e) =>
                setValue((a) => {
                  return { ...a, SiteName: e };
                })
              }
            />
          </Whisper>

          <label>Additional : </label>
          <Input
            name="textarea"
            rows={1}
            accepter={Textarea}
            value={value.Desc}
            onChange={(e) =>
              setValue((a) => {
                return { ...a, Desc: e };
              })
            }
          />

          <IconButton
            color="green"
            appearance="primary"
            onClick={SaveData}
            loading={processing}
            icon={<AddOutlineIcon />}
          >
            Add
          </IconButton>

          <Button
            color="green"
            appearance="primary"
            onClick={handleRefresh}
            loading={processing1}
          >
            <ReloadIcon style={{ margin: "5px" }} /> Refresh
          </Button>
        </Stack>
      </div>
      <Header>
        <h5
          style={{ color: "white", fontSize: 20 }}
        >{`List of Sites (${data.length})`}</h5>
      </Header>
      <div className="InputContainer">
        <Table>
          <thead>
            <tr>
              {column.map((a) => (
                <th key={a.field}>{a.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {siteList.map((a) => (
              <tr key={a.SrNo}>
                <td>{a.Sitename}</td>
                <td>{a.Note}</td>

                <td width={60}>
                  <IconButton
                    icon={<TrashIcon />}
                    color="red"
                    appearance="primary"
                    circle
                    onClick={() => handleDelete(a.SrNo, a.Sitename)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ManageSites;
