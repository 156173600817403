import React from "react";
import { Sidenav, Nav } from "rsuite";
import DashboardIcon from "@rsuite/icons/Dashboard";
import PeoplesIcon from "@rsuite/icons/Peoples";
import { useNavigate } from "react-router-dom";
import PeopleBranchIcon from "@rsuite/icons/PeopleBranch";
import OperatePeopleIcon from "@rsuite/icons/OperatePeople";
import ModelIcon from "@rsuite/icons/Model";
import PeoplesMapIcon from "@rsuite/icons/PeoplesMap";
import { useSelector } from "react-redux";
const styles = {
  width: 240,
  display: "inline-table",
  marginRight: 10,
};

const Sidebar = ({
  appearance,
  openKeys,
  expanded,
  onOpenChange,
  onExpand,
  ...props
}) => {
  const displayName = useSelector(
    (state) => state.nameReducer.user.userDetails
  );
  const Type = displayName.Type;
  const newFunction = () => {
    onExpand(!expanded);
  };
  const navigate = useNavigate();
  return (
    <div style={styles}>
      <Sidenav appearance={appearance} defaultOpenKeys={openKeys}>
        <Sidenav.Body>
          <Nav {...props}>
            <Nav.Item
              eventKey="1"
              icon={<PeoplesIcon />}
              onClick={() => navigate("ManageUsers")}
            >
              List Users
            </Nav.Item>

            <Nav.Item eventKey="2" onClick={() => navigate("ViewLabours")}>
              Manage Labours
            </Nav.Item>
            <Nav.Item eventKey="3" onClick={() => navigate("ViewAttendance")}>
              Attendance
            </Nav.Item>
            <Nav.Item eventKey="4" onClick={() => navigate("GetSalary")}>
              Salary
            </Nav.Item>
            <Nav.Item eventKey="5" onClick={() => navigate("MarkAttendance")}>
              Mark Attendance
            </Nav.Item>
            <Nav.Item eventKey="6" onClick={() => navigate("Summary")}>
              Summary
            </Nav.Item>
            <Nav.Item eventKey="7" onClick={() => navigate("OtherLabours")}>
              Other Labours
            </Nav.Item>

            <Nav.Item
              eventKey="8"
              icon={<PeoplesIcon />}
              onClick={() => navigate("ManageSites")}
            >
              Manage Sites
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
        <Sidenav expanded={expanded} onToggle={newFunction} />
      </Sidenav>
    </div>
  );
};

export default Sidebar;
